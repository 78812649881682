import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TopPickUpItem from "./TopPickUpItem";
// NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
//import { useEnvironment } from "~/context/EnvironmentContext";

const TopPickUp = () => {
  // コンテキストから静的画像バケットのエンドポイントを取得
  // NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
  // const { staticImageBucketEndpoint } = useEnvironment();

  // NOTE: className周りでうまく画像取得できない問題があり、環境関係なく本番のR2を見に行くようにしているためコメントアウト
  // 画像URLを変数に格納（classNameで呼び出すため事前に変数に格納）
  //const pickupArrowImageLeftURL = `url(${staticImageBucketEndpoint}/public/top/pickup-arrow-left.webp)`;
  //const pickupArrowImageRightURL = `url(${staticImageBucketEndpoint}/public/top/pickup-arrow-right.webp)`;

  return (
    <section className="my-14 md:mt-20 md:mb-[104px]">
      <div className="px-4 md:px-7">
        <div className="max-w-[1040px] mx-auto">
          <div className="flex items-center sp:justify-center">
            <svg className="block w-5 h-5 mr-1 fill-primary md:w-6 md:h-6">
              <use xlinkHref="/symbol-defs.svg#icon-egg"></use>
            </svg>
            <p className="text-primary text-sm font-candal tracking-[0.06em] md:text-lg md:tracking-[0.06em]">
              Pick Up
            </p>
          </div>
          <h2 className="mb-3 text-2xl text-center font-black leading-normal md:text-left md:text-[32px] md:leading-normal">
            ピックアップ求人
          </h2>
        </div>
      </div>
      <div
        id="pickupSlider"
        className={`[&_.swiper-button-prev:after]:bg-[url(https://public.fjob-kyujin.com/public/top/pickup-arrow-left.webp)] [&_.swiper-button-next:after]:bg-[url(https://public.fjob-kyujin.com/public/top/pickup-arrow-right.webp)]`}
      >
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 16,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
        >
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/acbda9d6f20b2da146f46bac223df848d38900602100de1f9d8c9cdc7dc84630.html"
              company="株式会社クレア"
              title="【深夜勤務無し】台東区エリアの居酒屋「からあげ専門店千ちゃん・やきとりさくら田原町店」調理長候補募集中！"
              payment="30〜40万円"
              location="当東京都台東区"
              holiday="～月9日休み+夏季休暇+冬季休暇、他～"
            >
              <li>料理長</li>
              <li>焼き鳥（低単価）</li>
              <li>居酒屋</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/51464beef0be5b0b7f1e98426d4dda41b25143eb5a58eeae9f792e6f5a4fdc87.html"
              company="株式会社神戸物産"
              title="【上場企業】新規事業のメニュー開発担当/完全週休2日のワークライフバランスの整った環境でご活躍できます。"
              payment="25〜32万円"
              location="兵庫県加古川市"
              holiday="★完全週休2日制（原則土日）★年間休日118日（有給消化で128日～）"
            >
              <li>商品企画/開発</li>
              <li>焼肉/ホルモン焼き</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/0707b1f4066cdb0a7f72a5e6deaeb68666884c09c5f9d0c32e2c9297f0a8ce83.html"
              company="株式会社ギフトホールディングス"
              title="【年収600万円】千代田区にあるラーメン店「町田商店 水道橋店」にて店長候補募集中！全国多数展開中◎"
              payment="27〜45万円"
              location="東京都千代田区"
              holiday="～週休二日制　他～"
            >
              <li>店長</li>
              <li>ラーメン/つけ麺</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/5f1db0aa0b778a3f95c3387a9d169a8b5d35710ec5633f09b707c51b4c6f1878.html"
              company="株式会社 一家ホールディングス"
              title="【未経験OK】新宿区エリアにある居酒屋「屋台屋博多劇場」にて店長候補の募集！上場企業のグループ企業！"
              payment="25〜35万円"
              location="東京都新宿区"
              holiday="～月8~9日休み（シフト制）、他～"
            >
              <li>店長</li>
              <li>居酒屋</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/777ac9dad6e4907d54664ab5326ad1661b897f727c2568452a865531562ae4ca.html"
              company="株式会社フォンス"
              title="【年収480万円】渋谷の蕎麦ダイニング「酢重正之酢重Indigo」のホールスタッフ募集！マニュアルに捉われないホスピタリティを発揮できます！"
              payment="28〜40万円"
              location="東京都渋谷区"
              holiday="～月8日休み+有給休暇+リフレッシュ休暇～"
            >
              <li>ホール</li>
              <li>和食（割烹/懐石/会席）</li>
              <li>そば</li>
              <li>居酒屋</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/eaea4a7c99f8ee105c7c0e9d1f6dc665a23e74b14dd783ee79767bd1c24f8e36.html"
              company="株式会社サンパーク"
              title="【年収550万円】行徳のカフェ「高木珈琲」の店長募集！FC本部としての事業拡大につきキャリアアップの可能性大！"
              payment="32〜39万円"
              location="千葉県市川市"
              holiday="～週休2日制+7連休制度、他～"
            >
              <li>店長</li>
              <li>カフェ（フルサービス）</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/71f03323d7136404d00d902518c335c377f02ca05d804c1867d4eca9c20c0d8f.html"
              company="株式会社ダイゴインターナショナル"
              title="【年収510万円】千代田区にある焼肉店で料理長候補募集！残業手当全額支給・インセンティブ支給あり・業績賞与あり。評価が給与に還元される環境です！"
              payment="35〜40万円"
              location="東京都千代田区"
              holiday="月8日休み～月9日休み（週休2日制）夏季休暇、年末年始、慶弔休暇、有給休暇"
            >
              <li>料理長</li>
              <li>焼肉/ホルモン焼き</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/285e59636fb20549a970bde016a01e552f99c4dc33a96600293807b69fc2fb28.html"
              company="株式会社トリドールホールディングス"
              title="【年収480万円】堺市堺区にある「丸亀製麺 堺店」にて店長募集中！"
              payment="24〜35万円"
              location="大阪府堺市堺区"
              holiday="～月7～10日休み、7連休取得可◎～ 有給休暇 結婚休暇／5日 産前産後休暇 育児介護休暇 リフレッシュ休暇"
            >
              <li>店長</li>
              <li>うどん</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/0c7587a65c6fbd2c8c4d6128d40a5f9f4e842494988fe931fea250e3de8891c6.html"
              company="株式会社 LATTE GRAPHIC"
              title="【年収480万円】目黒区で人気のオーストラリアスタイルのカフェ「LATTE GRAPHIC 自由が丘店」にて料理長募集！"
              payment="25.4〜40万円"
              location="東京都目黒区"
              holiday="～月6〜10日休み+元旦定休、他～"
            >
              <li>料理長</li>
              <li>カフェ（フルサービス）</li>
            </TopPickUpItem>
          </SwiperSlide>
          <SwiperSlide>
            <TopPickUpItem
              linkUrl="/detail/bfc7e3567944210fa89295c8dae0efaf7bcb5ea567645476bbb2f450bd8a9a7e.html"
              company="株式会社ウェイブズ"
              title="【年間休日110日以上】有楽町エリアのイノベーティブ中華×スパニッシュレストラン「TexturA」のホールスタッフを募集中！"
              payment="28〜33万円"
              location="東京都千代田区"
              holiday="～週休2日制+定休日あり+年末年始休暇、他～"
            >
              <li>ホール</li>
              <li>中華（高単価）</li>
              <li>スペイン料理</li>
            </TopPickUpItem>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default TopPickUp;
