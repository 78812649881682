import React, { ReactNode } from "react";
import MediumButton from "../MediumButton";

type Props = {
  company: string;
  title: string;
  payment: string;
  location: string;
  holiday: string;
  linkUrl: string;
  children: ReactNode;
};
const TopPickUpItem: React.FC<Props> = (props) => {
  return (
    <article className="relative h-full pb-[84px] font-medium md:pb-[96px] rounded-lg overflow-hidden bg-white shadow-[2px_2px_8px_0px_rgba(51,51,51,0.25)]">
      <a href={props.linkUrl} className="group block">
        <div className="p-4 md:p-5">
          <p className="mb-1 text-gray text-sm leading-[1.8]">
            {props.company}
          </p>
          <h4 className="mb-3 text-base font-bold leading-normal line-clamp-3 md:mb-4">
            {props.title}
          </h4>
          <ul className="mt-3 mb-2 flex flex-wrap md:mt-4 md:mb-3 [&_li]:mr-2 [&_li]:mb-1 [&_li]:border [&_li]:border-brown [&_li]:rounded-[3px] [&_li]:bg-gray-light [&_li]:text-brown [&_li]:text-xs [&_li]:font-bold [&_li]:leading-[1.2] [&_li]:py-0.5 [&_li]:px-2">
            {props.children}
          </ul>
          <ul className="grid grid-cols-1 gap-y-1 text-sm leading-[1.8] [&_li]:flex [&_li]:items-start">
            <li>
              <span className="flex-shrink-0 aspect-square flex items-center justify-center w-6 mr-2 bg-gray-light rounded-full translate-y-[8px]">
                <svg className="block aspect-square w-[14px] fill-primary">
                  <use xlinkHref="/symbol-defs.svg#icon-dollar"></use>
                </svg>
              </span>
              <p className="line-clamp-1">
                月給
                <strong className="ml-1 text-secondary text-2xl font-black">
                  {props.payment}
                </strong>
              </p>
            </li>
            <li>
              <span className="flex-shrink-0 aspect-square flex items-center justify-center w-6 mr-2 bg-gray-light rounded-full">
                <svg className="block aspect-square w-[14px] fill-primary">
                  <use xlinkHref="/symbol-defs.svg#icon-pin2"></use>
                </svg>
              </span>
              <p className="line-clamp-1">{props.location}</p>
            </li>
            <li>
              <span className="flex-shrink-0 aspect-square flex items-center justify-center w-6 mr-2 bg-gray-light rounded-full">
                <svg className="block aspect-square w-[14px] fill-primary">
                  <use xlinkHref="/symbol-defs.svg#icon-holiday"></use>
                </svg>
              </span>
              <p className="line-clamp-1">{props.holiday}</p>
            </li>
          </ul>
        </div>
        <div className="absolute bottom-0 left-0 w-full p-4 bg-gray-light text-center md:py-5 [&_button]:pointer-events-none [&_button]:w-full [&_button]:max-w-[240px] md:[&_button]:max-w-[256px] md:[&_button]:px-1 group-hover:[&_button]:bg-primary-gradient-hover">
          <MediumButton
            text="求人詳細を見る"
            icon="arrow-right"
            color="primary-gradient"
          ></MediumButton>
        </div>
      </a>
    </article>
  );
};

export default TopPickUpItem;
