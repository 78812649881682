import type { MetaFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { useEffect } from "react";
import { useLoaderData } from "@remix-run/react";

import { INDEX_META, STATIC_IMAGES } from "~/constants";

import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyVisual from "../components/top/KeyVisual";
import TopSearch from "../components/top/TopSearch";
import TopPickUp from "../components/top/TopPickUp";
import Logos from "../components/top/Logos";
import SearchByBiz from "../components/top/SearchByBiz";
import SearchByPosition from "../components/top/SearchByPosition";
import SearchByArea from "../components/top/SearchByArea";
import Contact1 from "../components/top/Contact1";
import AboutUs from "../components/top/AboutUs";
import Recommend from "../components/top/Recommend";
import Flow from "../components/top/Flow";
import Contact2 from "../components/top/Contact2";

import { useScrollToAnchor } from "~/hooks";

import type { Masters } from "~/types";
import { loadMasters } from "~/lib/queries/masters";

export const meta: MetaFunction = () => {
  return [
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: INDEX_META.TITLE,
        url: INDEX_META.URL,
      },
    },
    { title: INDEX_META.TITLE },
    {
      name: "description",
      content: INDEX_META.DESCRIPTION,
    },
    {
      property: "og:title",
      content: INDEX_META.TITLE,
    },
    {
      property: "og:description",
      content: INDEX_META.DESCRIPTION,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: STATIC_IMAGES.OG_IMAGE,
    },
    {
      property: "og:url",
      content: INDEX_META.URL,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
  ];
};

export const loader = async ({ context }: LoaderFunctionArgs) => {
  try {
    return await loadMasters(context.db);
  } catch (error) {
    console.error("Failed to load masters:", error);
    throw new Response("Internal Server Error", { status: 500 });
  }
};

export default function Index() {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });
  const masters = useLoaderData<Masters>();

  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  return (
    <div>
      <Header page="top" />
      <main>
        <KeyVisual />
        <TopSearch masters={masters} />
        <TopPickUp />
        <SearchByBiz masters={masters} />
        <SearchByPosition masters={masters} />
        <SearchByArea masters={masters} />
        <Logos />
        <Contact1 masters={masters} />
        <AboutUs />
        <Recommend />
        <Flow />
        <Contact2 />
      </main>
      <Footer />
    </div>
  );
}
